import "./App.css";
import { AppBar, Toolbar, Typography, Container, Grid } from "@mui/material";

import HelloThereText from "./components/HelloThereText";
import TechStack from "./components/TechStack";
import GetInTouch from "./components/GetInTouch";
import MainText from "./components/MainText";

function App() {
  return (
    <Grid container className="App">
      <Grid item md={12} xs={12}>
        <AppBar
          position="static"
          sx={{ backgroundColor: "#161819", mt: { xs: 0, md: 10 } }}
        >
          <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }}>
                <Grid item xs={1}></Grid>
                <Grid item xs={11} display="flex">
                  <Typography
                    variant="h6"
                    noWrap
                    component="p"
                    sx={{
                      fontFamily: "sans-serif",
                      fontWeight: 200,
                      letterSpacing: ".3rem",
                      color: "inherit",
                      fontSize: "2rem",
                      ml: 3,
                    }}
                  >
                    AD_
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ display: { xs: "none", md: "flex" } }}>
                <Grid item xs={4}>
                  <Typography
                    variant="h5"
                    noWrap
                    component="a"
                    href="#app-bar-with-responsive-menu"
                    sx={{
                      mr: 2,
                      flexGrow: 1,
                      fontFamily: "sans-serif",
                      fontSize: "2rem",
                      fontWeight: 100,
                      letterSpacing: ".3rem",
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    AD_
                  </Typography>
                </Grid>
                <Grid item xs={3} />
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: { xs: "none", md: "flex" },
                    justifyContent: "center",
                  }}
                ></Grid>
                <Grid item xs={2} />
              </Grid>
            </Toolbar>
          </Container>
        </AppBar>
      </Grid>

      <Grid item lg={3} md={2} />
      <Grid item lg={6} md={8} xs={12}>
        <MainText />
      </Grid>
      <Grid item lg={3} md={2} />

      <Grid item lg={3} md={2} xs={0} />
      <Grid item lg={6} md={8} xs={12}>
        <TechStack />
      </Grid>
      <Grid item lg={3} md={2} xs={0} />

      <Grid item lg={3} md={2} xs={0} />
      <Grid item lg={6} md={8} xs={12}>
        <GetInTouch />
      </Grid>
      <Grid item lg={3} md={2} xs={0} />

      <HelloThereText />
    </Grid>
  );
}

export default App;
