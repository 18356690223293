import { Grid, Typography } from "@mui/material";
import LinkedInIcon from "../icons/LinkedInIcon";
import EmailIcon from "../icons/EmailIcon";
import CustomButton from "./CustomButton";

const GetInTouch = () => (
  <Grid container mt={5} justifyContent="space-evenly">
    <Grid item xs={12}>
      <Typography
        variant="h3"
        noWrap
        component="p"
        mx={2}
        sx={{
          color: "#ffffff",
          fontSize: "1rem",
          textAlign: "left",
          fontWeight: "bold",
        }}
      >
        Get in touch
      </Typography>
    </Grid>

    <Grid item xs={0} sm={2} mt={1} />
    <Grid item xs={6} sm={4} mt={1}>
      <CustomButton
        icon={<LinkedInIcon />}
        title="LinkedIn"
        onPress={() => {
          window.location.href = "https://linkedin.com/in/alex-dragnea";
        }}
      />
    </Grid>
    <Grid item xs={6} sm={4} mt={1}>
      <CustomButton
        icon={<EmailIcon />}
        title="E-mail"
        onPress={() => {
          window.location.href = "mailto:dragnea.alex@gmail.com";
        }}
      />
    </Grid>
    <Grid item xs={0} sm={2} mt={1} />

    <Grid item xs={12} height={40} />
  </Grid>
);

export default GetInTouch;
