import { Button, Typography } from "@mui/material";
import React from "react";

type ButtonType = {
  icon: JSX.Element,
  title: string,
  onPress: () => void,
}

const CustomButton = ({icon, title, onPress}: ButtonType) => (
  <Button 
    sx={{
      backgroundColor: '#222222', 
      position: 'relative', 
      zIndex: 1,
      borderRadius: 10,
      paddingLeft: 3,
      paddingRight: 3,
      height: 40,
      textTransform: 'none',
      "&.MuiButtonBase-root:hover": {
        bgcolor: "#222222"
      },
    }}
    onClick={onPress}>
    {icon}
    <Typography 
      variant="h3" 
      noWrap
      component="p"
      mx={2}
      sx={{
        color: "#ffffff",
        fontSize: '0.9rem',
        textAlign: 'left',
        fontWeight: 'bold',
        textDecoration: 'none'
      }}>{title}</Typography>
  </Button>
);

export default CustomButton;