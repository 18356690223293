import { Grid, Typography } from "@mui/material";

type TechStackIconType = {
  icon: JSX.Element;
  title: string;
};

const TechStackIcon = ({ icon, title }: TechStackIconType) => (
  <Grid container xs={12}>
    <Grid item lg={3} md={3} xs={3} />
    <Grid
      item
      lg={6}
      md={6}
      xs={6}
      sx={{
        backgroundColor: "#222222",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        zIndex: 1,
        borderRadius: 2,
        maxWidth: "100px",
        maxHeight: "100px",
        p: { xs: 2, md: 3 },
      }}
    >
      {icon}
    </Grid>
    <Grid item lg={3} md={3} xs={3} />

    <Grid item lg={3} md={3} xs={3} />
    <Grid item lg={6} md={6} xs={6}>
      <Typography
        variant="h3"
        noWrap
        component="p"
        sx={{
          color: "#ffffff",
          fontSize: { xs: "0.8rem", md: "0.9rem" },
          overflow: "visible",
          textAlign: "center",
          mt: 1,
        }}
      >
        {title}
      </Typography>
    </Grid>
    <Grid item lg={3} md={3} xs={3} />
  </Grid>
);

export default TechStackIcon;
