import React from 'react';

const CjIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.1028 35.3739C14.3225 35.3739 12.9767 34.8552 12.0514 33.8178C11.1262 32.7804 10.6636 31.2944 10.6636 29.3598C10.6636 27.3832 11.1402 25.8692 12.1075 24.8038C13.0748 23.7383 14.4626 23.2056 16.2991 23.2056C17.5327 23.2056 18.6542 23.4299 19.6355 23.8925L18.8785 25.8972C17.8271 25.4907 16.958 25.2804 16.2711 25.2804C14.2383 25.2804 13.229 26.6262 13.229 29.3178C13.229 30.6355 13.4813 31.6168 13.986 32.2897C14.4907 32.9486 15.2337 33.2851 16.215 33.2851C17.3225 33.2851 18.3739 33.0047 19.3692 32.458V34.6309C18.9206 34.8972 18.4439 35.0795 17.9393 35.1916C17.4346 35.3178 16.8178 35.3739 16.1028 35.3739Z" fill="white"/>
      <path d="M26.2524 35.1636H23.7571V18.6495H26.2524V35.1636Z" fill="white"/>
      <path d="M30.2477 40.3785C29.5047 40.3785 28.8738 40.2944 28.3832 40.1122V38.1355C28.8598 38.2617 29.3505 38.3318 29.8551 38.3318C30.9206 38.3318 31.4533 37.729 31.4533 36.5234V23.4159H33.9486V36.6916C33.9486 37.8972 33.6262 38.8225 32.9953 39.4393C32.3505 40.0701 31.4393 40.3785 30.2477 40.3785ZM31.2991 20.3178C31.2991 19.8692 31.4252 19.5327 31.6636 19.2944C31.9019 19.0561 32.2523 18.9299 32.715 18.9299C33.1495 18.9299 33.5 19.0561 33.7383 19.2944C33.9766 19.5327 34.1028 19.8832 34.1028 20.3178C34.1028 20.7383 33.9766 21.0748 33.7383 21.3271C33.5 21.5654 33.1495 21.6916 32.715 21.6916C32.2664 21.6916 31.9159 21.5654 31.6636 21.3271C31.4112 21.0748 31.2991 20.7383 31.2991 20.3178Z" fill="white"/>
      <path d="M47.0141 31.8271C47.0141 32.9766 46.5935 33.8458 45.7664 34.4626C44.9253 35.0794 43.7337 35.3738 42.1776 35.3738C40.6075 35.3738 39.3599 35.1355 38.4066 34.6589V32.5C39.7804 33.1308 41.0701 33.4532 42.2617 33.4532C43.7898 33.4532 44.5608 32.9906 44.5608 32.0654C44.5608 31.771 44.4767 31.5187 44.3084 31.3224C44.1402 31.1261 43.8599 30.9159 43.4673 30.7056C43.0748 30.4953 42.5421 30.257 41.8412 29.9906C40.4954 29.4719 39.5701 28.9392 39.0935 28.4205C38.6169 27.9018 38.3785 27.2149 38.3785 26.3878C38.3785 25.3785 38.7851 24.6075 39.5982 24.0467C40.4113 23.486 41.5047 23.2196 42.9066 23.2196C44.2804 23.2196 45.5841 23.5 46.8178 24.0607L46.0047 25.9392C44.743 25.4205 43.6776 25.1542 42.8084 25.1542C41.4907 25.1542 40.8318 25.5327 40.8318 26.2757C40.8318 26.6402 41 26.9486 41.3505 27.2149C41.687 27.4673 42.444 27.8177 43.5935 28.2663C44.5608 28.6448 45.2617 28.9813 45.7103 29.2897C46.1449 29.5981 46.4673 29.9626 46.6916 30.3691C46.9019 30.7617 47.0141 31.2523 47.0141 31.8271Z" fill="white"/>
      <path d="M26.6169 53.0514C13.8318 51.9159 3.76638 41.1355 3.76638 28.0561C3.76638 14.9766 13.8318 4.21029 26.6169 3.07478V0.257019C12.2757 1.40655 0.962646 13.4346 0.962646 28.0701C0.962646 42.7056 12.2757 54.7337 26.6169 55.8832V53.0514Z" fill="white"/>
      <path d="M31.1028 0.257019V3.07478C43.8878 4.21029 53.9533 14.9907 53.9533 28.0701C53.9533 41.1495 43.8878 51.9299 31.1028 53.0654V55.8832C45.4439 54.7337 56.757 42.7056 56.757 28.0701C56.757 13.4346 45.4439 1.40655 31.1028 0.257019Z" fill="white"/>
    </svg>
  );
}

export default CjIcon;