import React from 'react';

const EmailIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0V18H24V0H0ZM21.518 2L12 9.713L2.482 2H21.518ZM2 16V4.183L12 12.287L22 4.183V16H2Z" fill="#27CB8D"/>
    </svg>
  );
};

export default EmailIcon;