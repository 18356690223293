import { Box, Typography } from '@mui/material';

const HelloThereText = () => (
  <Box sx={{position: 'absolute', top: {xs: '220px', sm: '200px', md: '300px'}, left: '20px'}}>
    <Typography 
      variant="h5" 
      noWrap
      component="p"
      sx={{
        color: "#ffffff",
        fontSize: {
          xs: '4rem',
          sm: '8rem',
          md: '12rem',
          lg: '16rem'
        },
        textAlign: 'left',
        opacity: 0.1
      }}>
      hello there 
    </Typography>
  </Box>
);

export default HelloThereText;