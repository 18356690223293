import { Grid, Typography } from "@mui/material";

const MainText = () => (
  <Grid mt={10} container>
    <Grid item xs={12}>
      <Typography
        variant="h5"
        component="p"
        ml={2}
        sx={{
          color: "#ffffff",
          fontSize: "3rem",
          textAlign: "left",
        }}
      >
        I’m Alex Dragnea 👋
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Typography
        variant="h5"
        noWrap
        component="p"
        mx={2}
        sx={{
          color: "#27CB8D",
          fontSize: "3rem",
          textAlign: "left",
        }}
      >
        App Developer
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Typography
        variant="h5"
        component="p"
        mx={2}
        sx={{
          color: "#ffffff",
          fontSize: { xs: "0.90rem", md: "1rem" },
          textAlign: "left",
          mt: 2,
          lineHeight: 1.6,
        }}
      >
        I’ve been developing mobile and web applications across diverse
        industries
        <br />
        including automotive, fashion, social media, healthcare and banking.
        <br />I thrive in collaborative environments, where brainstorming and
        refining
        <br />
        ideas collectively lead to enhancing products.
      </Typography>
    </Grid>
  </Grid>
);

export default MainText;
