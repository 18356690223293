import { Grid, Typography } from "@mui/material";
import JsIcon from "../icons/JsIcon";
import TsIcon from "../icons/TsIcon";
import CjIcon from "../icons/CjIcon";
import HtmlIcon from "../icons/HtmlIcon";
import CssIcon from "../icons/CssIcon";
import TailwindIcon from "../icons/TailwindIcon";
import ReactIcon from "../icons/ReactIcon";
import ReduxIcon from "../icons/ReduxIcon";
import TechStackIcon from "./TechStackIcon";

const TechStack = () => (
  <Grid item xs={12} mt={5}>
    <Grid item xs={12}>
      <Typography
        variant="h3"
        noWrap
        component="p"
        mx={2}
        sx={{
          color: "#ffffff",
          fontSize: "1rem",
          textAlign: "left",
          fontWeight: "bold",
        }}
      >
        Tech stack
      </Typography>
    </Grid>

    <Grid
      item
      container
      rowSpacing={2}
      columnSpacing={{ xs: 2, md: 4, lg: 8, xl: 16 }}
      mt={1}
    >
      <Grid item xs={4}>
        <TechStackIcon icon={<JsIcon />} title="JavaScript" />
      </Grid>
      <Grid item xs={4}>
        <TechStackIcon icon={<TsIcon />} title="TypeScript" />
      </Grid>
      <Grid item xs={4}>
        <TechStackIcon icon={<CjIcon />} title="ClojureScript" />
      </Grid>
      <Grid item xs={4}>
        <TechStackIcon icon={<HtmlIcon />} title="HTML" />
      </Grid>
      <Grid item xs={4}>
        <TechStackIcon icon={<CssIcon />} title="CSS" />
      </Grid>
      <Grid item xs={4}>
        <TechStackIcon icon={<TailwindIcon />} title="Tailwind" />
      </Grid>
      <Grid item xs={4}>
        <TechStackIcon icon={<ReactIcon />} title="React" />
      </Grid>
      <Grid item xs={4}>
        <TechStackIcon icon={<ReactIcon />} title="React Native" />
      </Grid>
      <Grid item xs={4}>
        <TechStackIcon icon={<ReduxIcon />} title="Redux" />
      </Grid>
    </Grid>
  </Grid>
);

export default TechStack;
